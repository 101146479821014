import * as React from 'react';
import EventStepper, { EventStepperGroup } from '../../../../../components/EventStepper';
import InputHandler from '../../../../../components/InputHandler';
import { formatPartialDate, isPartialDate, nowPartialDate, partialDateToValue, sortPartialDate } from 'neuro-utils';
import { StepperHeaderFormInputPair, StepperHeaderValuePair } from 'Components/EventStepper/components';
import Treatments from './components/Treatments';
import { RTMSContext } from '../..';
import TabContent from 'Components/TabContent';
import Treatment from './components/Treatment';
import { equals } from 'ramda';
import {
  addingTreatmentSessionDisabledReason,
  convertSymptomsAndLocationsToRatingFormat,
  findPrecedingDoctorsOrder,
  getSessionNumber,
  subjectOfTreatmentTitle,
} from 'Utility/ninmtUtil';
import { FormattedDoctorsOrderValue } from '../../functions';
import { RenderAlerts } from 'Components/Alerts';
import PatientsRatings from './components/PatientsRatings';
import colors from '../../../../../config/theme/colors';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import TasklistDialog from './components/TaskListDialog';
import { styled } from '@mui/system';
import { actions } from 'Store/myapp/actions';
import { useDispatch } from 'react-redux';
import { Task_Progress } from 'neuro-schemas';

const StyledRow = styled('div')({
  display: 'grid',
  gridTemplateColumns: '10fr 2fr',
  width: '100%',
});

type TTreatmentEfficiencyState =
  | 'treatmentEfficiencyNotStarted'
  | 'treatmentEfficiencyBeforePending'
  | 'treatmentEfficiencyBeforeCompleted'
  | 'treatmentEfficiencyAfterPending'
  | 'treatmentEfficiencyAfterCompleted'
  | undefined;

const Sessions = (): JSX.Element => {
  const rtmsContext = React.useContext(RTMSContext);
  const {
    formData,
    fm,
    doctorsOrders,
    allDoctorsOrders,
    ninmtTreatmentPeriods,
    contraIndications,
    symptomsAndLocations,
    setDoctorsOrdersReview,
    setAnchor,
    patientMySQStatus,
    taskLists,
    sortedAndMergedMyDocuments,
  } = rtmsContext;

  const dispatch = useDispatch();

  const currentTreatmentEfficiencyDocs = sortedAndMergedMyDocuments.filter(
    (d) => partialDateToValue(d.date) === partialDateToValue(nowPartialDate()) && d._type === 'treatmentEfficiency',
  );
  const before = currentTreatmentEfficiencyDocs.find((d) => d.timing === 'before');
  const after = currentTreatmentEfficiencyDocs.find((d) => d.timing === 'after');

  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchMyAppData = React.useCallback(() => actions.loadMyAppData(dispatch), [dispatch]);

  const currentTaskLists = taskLists.filter(
    (tl) =>
      tl.tasks?.some((task) => task.indexOf('treatmentEfficiency') > -1) &&
      partialDateToValue(tl.sendDate) === partialDateToValue(nowPartialDate()),
  );

  // Active tasklists are those which do not have 1ms after midnight as their deadline
  const activeTaskLists = currentTaskLists.filter((tl) => tl.end_time !== partialDateToValue(nowPartialDate()) + 1);

  // Reset tasklist deadline to 1ms after midnight to make it inactive
  const closeTaskLists = () => {
    // Only a tasklist with both treatmentEfficiencyBefore and treatmentEfficiencyAfter should be closed automatically
    const beforeAndAfterTaskList = activeTaskLists.find(
      (tl) => tl.tasks?.includes('treatmentEfficiencyBefore') && tl.tasks?.includes('treatmentEfficiencyAfter'),
    );
    if (beforeAndAfterTaskList) {
      actions
        .updateTasklist({ ...beforeAndAfterTaskList, end_time: partialDateToValue(nowPartialDate()) + 1 }, dispatch)
        .then(() => {
          return;
        })
        .catch(() => {
          return;
        });
    }
  };

  const refreshMyAppData = (): void => {
    setLoading(true);
    fetchMyAppData().then(() => {
      setLoading(false);
    });
  };

  const [taskListDialogOpen, setTaskListDialogOpen] = React.useState<boolean>(false);

  const getTreatmentEfficiencyState = (): TTreatmentEfficiencyState => {
    // Some tasklists have been sent --> treatment efficiency procedure has started
    if (currentTaskLists.length > 0 || currentTreatmentEfficiencyDocs.length > 0) {
      // Some surveys have been filled --> the "after treatment" survey is either not started, pending or completed
      if (currentTreatmentEfficiencyDocs.length > 0) {
        const beforeCompleted =
          before &&
          Task_Progress.calculateProgress('treatmentEfficiency', before).state === Task_Progress.State.Completed;
        const afterCompleted =
          after &&
          Task_Progress.calculateProgress('treatmentEfficiency', after).state === Task_Progress.State.Completed;

        if (beforeCompleted) {
          // The "after treatment" survey is completed --> treatment efficiency procedure is completed
          if (afterCompleted) return 'treatmentEfficiencyAfterCompleted';
          if (activeTaskLists.some((tl) => tl.tasks?.some((task) => task.indexOf('treatmentEfficiencyAfter') > -1))) {
            // The "before treatment" survey is completed and a new tasklist has been sent --> the "after treatment" survey is pending
            return 'treatmentEfficiencyAfterPending';
          }
          // The "before treatment" survey is completed and a new tasklist has not been sent --> the "after treatment" survey is not started
          return 'treatmentEfficiencyBeforeCompleted';
        }
      }
      if (activeTaskLists.some((tl) => tl.tasks?.some((task) => task.indexOf('treatmentEfficiencyBefore') > -1))) {
        // No completed treatment efficiency surveys --> the "before treatment" survey is pending
        return 'treatmentEfficiencyBeforePending';
      }
      // Tasklists have been sent but none of them is active --> treatment efficiency procedure has not started
      return 'treatmentEfficiencyNotStarted';
    }
    // No tasklists have been sent --> treatment efficiency procedure has not started
    return 'treatmentEfficiencyNotStarted';
  };

  const [treatmentEfficiencyState, setTreatmentEfficiencyState] =
    React.useState<TTreatmentEfficiencyState>(getTreatmentEfficiencyState());

  // Refresh treatment efficiency state
  React.useEffect(() => {
    setTreatmentEfficiencyState(getTreatmentEfficiencyState());
  }, [taskLists, sortedAndMergedMyDocuments]);

  // Close tasklists when treatment efficiency procedure is completed
  React.useEffect(() => {
    if (treatmentEfficiencyState === 'treatmentEfficiencyAfterCompleted' && activeTaskLists.length > 0) {
      closeTaskLists();
    }
  }, [treatmentEfficiencyState]);

  // If all doctor's orders are "completed", no review is required
  const noReviewRequired = doctorsOrders.every((d) => d.completed);

  const buttonDisabledReason = addingTreatmentSessionDisabledReason('rtms', doctorsOrders, contraIndications, formData);

  // Add session createDate for more accurate sorting
  const creationMutator = (event: IRTMSSession): IRTMSSession => {
    return { ...event, createDate: Date.now() };
  };

  // Optionally require subjects of treatment, Device/RMT etc.
  const precedingDoctorsOrder = (index?: number, reqFields?: ('device' | 'rmt' | 'endRTMS')[], includeAll?: true) =>
    findPrecedingDoctorsOrder(
      'rtms',
      includeAll ? allDoctorsOrders : doctorsOrders,
      formData.document.sessions ?? [],
      index ?? 0,
      reqFields,
      includeAll ? ninmtTreatmentPeriods : undefined,
    );

  const precedingDocOrdBeforeAddingNewSes = React.useRef<IDoctorsOrder | undefined>(precedingDoctorsOrder(0, []));

  const [dateDefault, setDateDefault] = React.useState<'now' | PartialDate>('now');

  // Capping the session date
  React.useEffect(() => {
    // If preceding order document has ended rtms, cap the session date to that order date
    if (precedingDocOrdBeforeAddingNewSes.current && precedingDocOrdBeforeAddingNewSes.current.rtms?.endRTMS) {
      setDateDefault(precedingDocOrdBeforeAddingNewSes.current.date ?? 'now');
    } else {
      // If preceding order document has not ended rtms but future one has ended cap it to that
      const futureOrders = doctorsOrders.filter(
        (d) =>
          partialDateToValue(d.date) >
          partialDateToValue(formData.document.sessions?.[0]?.date ?? formData.document.date),
      );
      setDateDefault(futureOrders.find((o) => o.rtms?.endRTMS)?.date ?? 'now');
    }
  }, [precedingDocOrdBeforeAddingNewSes.current, formData.document.date]);

  const hasDeletedSessions =
    Array.isArray(formData.document.sessions) &&
    formData.document.sessions.length > 0 &&
    formData.document.sessions.some((s) => s.removeTS || s.removeInfo);

  // Hide/filter deleted sessions
  React.useEffect(() => {
    if (hasDeletedSessions) {
      formData.onChange?.({ sessions: formData.document.sessions?.filter((s) => !(s.removeTS || s.removeInfo)) });
    }
  }, [hasDeletedSessions]);

  // Initialize patient's rating based on current symptoms and locations
  const initializePatientsRating = (index: number, date?: PartialDate) => {
    const { document, onChange } = formData;

    const newSessions: Array<IRTMSSession> = structuredClone(document.sessions) ?? [];
    const newSession = newSessions[index];
    if (newSession && date) newSession.date = date;

    if (newSession && newSession.date) {
      const newPatientsRating = convertSymptomsAndLocationsToRatingFormat(newSession, symptomsAndLocations);

      newSession.patientsRating = newPatientsRating;
      onChange && onChange({ sessions: newSessions });
    }
  };

  return (
    <React.Fragment>
      <EventStepperGroup
        allClosed
        previousEventsCollapseLimit={5}
        previousEventsCollapseMessage={{ showMessage: 'rtms.showAllSessions', hideMessage: 'rtms.hideAllSessions' }}
      >
        <EventStepper
          name="sessions"
          formData={formData}
          mutators={{ creationMutator }}
          customCreateEventItem={
            noReviewRequired
              ? undefined
              : () => {
                  // If all doctor's orders are not completed, review is required
                  setAnchor('sessionsAndProtocols');
                  setDoctorsOrdersReview({ active: true, completed: false });
                }
          }
          deleteDialogMode="full"
          stepLabelText={(d: IRTMSSession): string => formatPartialDate(d?.date)}
          stepContent={(d: IRTMSSession): JSX.Element => (
            <React.Fragment>
              {['patientsRating', 'additionalInformation', 'device', 'rmt', 'subjectOfTreatment'].map((key, index) => {
                const currentIndex = formData.document.sessions?.findIndex((s) => equals(s, d));
                const value = d[key as keyof IRTMSSession];
                switch (key) {
                  case 'patientsRating': {
                    return (value as [])?.filter((s) => s).length > 0 || !Array.isArray(d.patientsRating) ? (
                      <React.Fragment key={`${key}${index}`}>
                        <StepperHeaderValuePair
                          header={
                            <div style={{ whiteSpace: 'nowrap', fontWeight: 400 }}>
                              {fm('rtms.patientsRating.title')}
                            </div>
                          }
                          value={''}
                        />
                        <div style={{ margin: '2rem 0 2rem -2.1rem' }}>
                          <PatientsRatings
                            editIndex={currentIndex ?? 0}
                            editingEvent={false}
                            formData={formData}
                            fm={fm}
                            symptomsAndLocations={symptomsAndLocations}
                            sessionDate={d.date}
                          />
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={`${key}${index}`} />
                    );
                  }
                  case 'subjectOfTreatment': {
                    return (value as [])?.filter((s) => s).length > 0 ? (
                      <React.Fragment key={`${key}${index}`}>
                        <StepperHeaderValuePair header={fm('rtms.subjectOfTreatment.title')} value={''} />
                        <div style={{ margin: '2rem 0 2rem -2.1rem' }}>
                          <TabContent>
                            {(value as [])?.map((s: IRTMSTreatment, i: number) => ({
                              key: `${s.id}${i}`,
                              id: `${s.id}Tab${i}`,
                              title: subjectOfTreatmentTitle(s, 'rtms', fm),
                              ended: s.deleted,
                              content: (
                                <Treatment
                                  document={s}
                                  onChange={(): string => ''}
                                  session={d}
                                  sessionNumber={getSessionNumber(
                                    formData.document?.sessions ?? [],
                                    currentIndex ?? 0,
                                    s,
                                  )}
                                  precedingDoctorsOrder={precedingDoctorsOrder(currentIndex)}
                                  editingEvent={false}
                                />
                              ),
                              count: i,
                            }))}
                          </TabContent>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={`${key}${index}`} />
                    );
                  }
                  default: {
                    return (
                      <StepperHeaderValuePair
                        key={`${key}${index}`}
                        header={fm(`rtms.${key === 'additionalInformation' ? 'additionalInformationSession' : key}`)}
                        value={
                          ['device', 'rmt'].includes(key) ? (
                            <FormattedDoctorsOrderValue
                              name={key as 'device' | 'rmt'}
                              precedingDoctorsOrder={precedingDoctorsOrder(currentIndex, [key as 'device' | 'rmt'])}
                            />
                          ) : (
                            value || '-'
                          )
                        }
                      />
                    );
                  }
                }
              })}
            </React.Fragment>
          )}
          addNewTextHeader="rtms.session"
          addNewTextButton="rtms.newSession"
          buttonDisabled={buttonDisabledReason ? true : false}
          buttonDisabledElement={
            <RenderAlerts
              alerts={[{ id: 'rtmsAlert', textID: `rtms.${buttonDisabledReason}Message`, severity: 'info' }]}
              elevation={0}
              width={80}
            />
          }
          previousEventsTextHeader="rtms.previousSessions"
          editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
            return (
              <React.Fragment>
                <StepperHeaderFormInputPair
                  header={fm('general.date')}
                  input={
                    <InputHandler
                      type="PartialDate"
                      editing={true}
                      name="date"
                      formData={{
                        onChange: (values: TOnChangeValues) => {
                          if (onChange) {
                            const name = Object.keys(values)[0];
                            const value = values[name];
                            // Patient's rating should be re-initialized when session date is changed
                            if (
                              isPartialDate(value) &&
                              sortPartialDate(value, dateDefault === 'now' ? nowPartialDate() : dateDefault) !== 0
                            )
                              initializePatientsRating(index, value);
                            else onChange(values);
                          }
                        },
                        document: { date: formData.document.sessions?.[index]?.date || '' },
                      }}
                      dateDefault={dateDefault}
                      isNotCancellable={true}
                      dateHook={{
                        dateHookFloor: formData.document.date,
                        dateHookCeiling: precedingDoctorsOrder(0, [])?.rtms?.endRTMS
                          ? precedingDoctorsOrder(0, [])?.date
                          : undefined,
                      }}
                    />
                  }
                />
                <StepperHeaderFormInputPair
                  header={<div style={{ whiteSpace: 'nowrap' }}>{fm('rtms.patientsRating.title')}</div>}
                  input={<></>}
                />
                <div style={{ margin: '2rem 0 2rem -2.1rem' }}>
                  <PatientsRatings
                    editIndex={index}
                    formData={formData}
                    fm={fm}
                    symptomsAndLocations={symptomsAndLocations}
                    sessionDate={formData.document.sessions?.[index]?.date}
                    initializePatientsRating={initializePatientsRating}
                  />
                </div>
                <StepperHeaderFormInputPair
                  header={fm('rtms.additionalInformationSession')}
                  input={
                    <InputHandler
                      type="TextArea"
                      editing={true}
                      name="additionalInformation"
                      formData={{
                        onChange,
                        document: {
                          additionalInformation: formData.document.sessions?.[index]?.additionalInformation || '',
                        },
                      }}
                      placeholder="rtms.additionalInformationSession"
                    />
                  }
                />
                <StepperHeaderFormInputPair
                  header={fm('rtms.device')}
                  input={
                    <FormattedDoctorsOrderValue
                      name="device"
                      precedingDoctorsOrder={precedingDoctorsOrder(index, ['device'], true)}
                    />
                  }
                />
                <StepperHeaderFormInputPair
                  header={fm('rtms.rmt')}
                  input={
                    <FormattedDoctorsOrderValue
                      name="rmt"
                      precedingDoctorsOrder={precedingDoctorsOrder(index, ['rmt'], true)}
                    />
                  }
                />
                <StepperHeaderFormInputPair header={fm('rtms.subjectOfTreatment.title')} input={<></>} />
                <div style={{ margin: '2rem 0 2rem -2.1rem' }}>
                  <Treatments editIndex={index} formData={formData} fm={fm} doctorsOrders={doctorsOrders} />
                </div>
              </React.Fragment>
            );
          }}
          editingExtraElement={(index: number) => {
            const state = treatmentEfficiencyState;

            const currentSession = formData.document?.sessions?.[index];

            // Should not be able to send tasklists if...
            if (
              // There is no current session
              !currentSession ||
              // Current session date is not current date
              partialDateToValue(currentSession.date) !== partialDateToValue(nowPartialDate()) ||
              // There is an existing patientsRating
              currentSession.patientsRating ||
              // Patient does not use My service
              !patientMySQStatus ||
              // There are no symptoms and locations
              symptomsAndLocations?.filter((sl) => sl.symptom !== 'other' || !sl.mentalSymptom)?.length === 0 ||
              // Both before and after surveys have been filled
              state === 'treatmentEfficiencyAfterCompleted'
            ) {
              return <React.Fragment />;
            }

            let text;
            let buttonText;
            let buttonAction;

            switch (state) {
              case 'treatmentEfficiencyNotStarted':
                text = 'rtms.treatmentEfficiencyBeforeInfo';
                buttonText = 'rtms.askPatient';
                buttonAction = () => setTaskListDialogOpen(true);
                break;
              case 'treatmentEfficiencyBeforePending':
                text = 'rtms.treatmentEfficiencyBeforeActiveInfo';
                buttonText = 'rtms.update';
                buttonAction = () => refreshMyAppData();
                break;
              case 'treatmentEfficiencyAfterPending':
                text = 'rtms.treatmentEfficiencyAfterActiveInfo';
                buttonText = 'rtms.update';
                buttonAction = () => refreshMyAppData();
                break;
              case 'treatmentEfficiencyBeforeCompleted':
                text = 'rtms.treatmentEfficiencyAfterInfo';
                buttonText = 'rtms.askPatient';
                buttonAction = () => setTaskListDialogOpen(true);
                break;
              // Unused case - element should not be rendered
              default:
                text = 'rtms.treatmentEfficiencyBeforeInfo';
                buttonText = 'myService.newTaskList';
                buttonAction = () => setTaskListDialogOpen(true);
            }

            return (
              <div style={{ margin: '2rem 0 2rem 0' }}>
                <RenderAlerts
                  alerts={[
                    {
                      id: 'treatmentEfficiencyAlert',
                      textID: '',
                      customText: (
                        <React.Fragment>
                          <StyledRow style={{ marginBottom: '2rem' }}>
                            <div style={{ gridColumn: '1 / span 2' }}>{fm(text)}</div>
                          </StyledRow>
                          <StyledRow>
                            <div />
                            <div style={{ display: 'flex' }}>
                              {state === 'treatmentEfficiencyNotStarted' && (
                                <div style={{ marginRight: '1rem' }}>
                                  <ActionButtonRounded
                                    text="rtms.recordDataManually"
                                    onClick={() => initializePatientsRating(index)}
                                    loading={loading}
                                    colorScheme="dark"
                                    uppercase={false}
                                    fontSize={16}
                                    height={4}
                                    width={16}
                                    border={false}
                                  />
                                </div>
                              )}
                              <ActionButtonRounded
                                text={buttonText}
                                onClick={buttonAction}
                                loading={loading}
                                colorScheme="dark"
                                uppercase={false}
                                fontSize={16}
                                height={4}
                                width={16}
                              />
                            </div>
                          </StyledRow>
                        </React.Fragment>
                      ),
                      severity: 'info',
                    },
                  ]}
                  elevation={0}
                  width={80}
                />
              </div>
            );
          }}
        />
        <EventStepper
          name="unusedSessions"
          formData={formData}
          mutators={{ creationMutator }}
          stepLabelText={(d: IRTMSUnusedSession): string => formatPartialDate(d.date)}
          stepContent={(d: IRTMSUnusedSession): JSX.Element => (
            <React.Fragment>
              <StepperHeaderValuePair
                header={fm('rtms.unusedSessionReason')}
                value={d.reason ? fm(`rtms.opts.unusedSessionReason.${d.reason}`) : '-'}
              />
              {d.reason && (
                <React.Fragment>
                  <StepperHeaderValuePair
                    header={fm('rtms.unusedSessionReasonDetails')}
                    value={d.reasonDetails ? fm(`rtms.opts.unusedSessionReasonDetails.${d.reasonDetails}`) : '-'}
                  />
                  {d.reasonDetails === 'other' && (
                    <StepperHeaderValuePair
                      header={fm('rtms.unusedSessionReasonDetailsOther')}
                      value={d.reasonDetailsOther && d.reasonDetailsOther.length > 0 ? d.reasonDetailsOther : '-'}
                    />
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          addNewTextHeader="rtms.unusedSession"
          addNewTextButton="rtms.newUnusedSession"
          previousEventsTextHeader="rtms.previousUnusedSessions"
          editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
            const patientInducedReasonDetails = [
              'forgotten',
              'sick',
              'blockedByOtherReason',
              'confusionAboutSessionTime',
              'unknown',
              'other',
            ];
            const unitInducedReasonDetails = [
              'staffShortage',
              'deviceRelated',
              'cancelledDueToEmergencyPatient',
              'confusionInSessionBooking',
              'other',
            ];

            return (
              <React.Fragment>
                <StepperHeaderFormInputPair
                  header={fm('general.date')}
                  input={
                    <InputHandler
                      type="PartialDate"
                      editing={true}
                      name="date"
                      formData={{
                        onChange,
                        document: { date: formData.document.unusedSessions?.[index]?.date || '' },
                      }}
                      dateDefault={dateDefault}
                      isNotCancellable={true}
                      dateHook={{
                        dateHookFloor: formData.document.date,
                        dateHookCeiling: precedingDoctorsOrder(0, [])?.rtms?.endRTMS
                          ? precedingDoctorsOrder(0, [])?.date
                          : undefined,
                      }}
                    />
                  }
                />
                <StepperHeaderFormInputPair
                  header={fm('rtms.unusedSessionReason')}
                  input={
                    <InputHandler
                      type="Radio"
                      editing={true}
                      name="reason"
                      formData={{
                        onChange,
                        document: {
                          reason: formData.document.unusedSessions?.[index]?.reason || '',
                          reasonDetails: formData.document.unusedSessions?.[index]?.reasonDetails,
                          reasonDetailsOther: formData.document.unusedSessions?.[index]?.reasonDetailsOther,
                        },
                      }}
                      options={['patientInduced', 'unitInduced']}
                      optionFormatter={(id: string | number) => fm(`rtms.opts.unusedSessionReason.${id}`)}
                      dependentFieldsRemovalWarning={true}
                      dependentFieldsList={
                        formData.document.unusedSessions?.[index]?.reasonDetails !== 'other'
                          ? () => ['reasonDetails', 'reasonDetailsOther']
                          : undefined
                      }
                    />
                  }
                />
                {formData.document.unusedSessions?.[index]?.reason && (
                  <React.Fragment>
                    <StepperHeaderFormInputPair
                      header={fm('rtms.unusedSessionReasonDetails')}
                      input={
                        <InputHandler
                          type="Radio"
                          editing={true}
                          name="reasonDetails"
                          formData={{
                            onChange,
                            document: {
                              reasonDetails: formData.document.unusedSessions?.[index]?.reasonDetails || '',
                              reasonDetailsOther: formData.document.unusedSessions?.[index]?.reasonDetailsOther,
                            },
                          }}
                          options={
                            formData.document.unusedSessions?.[index]?.reason === 'patientInduced'
                              ? patientInducedReasonDetails
                              : unitInducedReasonDetails
                          }
                          optionFormatter={(id: string | number) => fm(`rtms.opts.unusedSessionReasonDetails.${id}`)}
                          dependentFieldsRemovalWarning={true}
                          dependentFieldsList={() => ['reasonDetailsOther']}
                        />
                      }
                    />
                    {formData.document.unusedSessions?.[index]?.reasonDetails === 'other' && (
                      <StepperHeaderFormInputPair
                        header={fm('rtms.unusedSessionReasonDetailsOther')}
                        input={
                          <InputHandler
                            type="TextArea"
                            editing={true}
                            name="reasonDetailsOther"
                            formData={{
                              onChange,
                              document: {
                                reasonDetailsOther: formData.document.unusedSessions?.[index]?.reasonDetailsOther || '',
                              },
                            }}
                            placeholder="rtms.unusedSessionReasonDetailsOther"
                          />
                        }
                      />
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            );
          }}
          theme={{ highlightColor: colors.highlight.light }}
        />
      </EventStepperGroup>
      {treatmentEfficiencyState && (
        <TasklistDialog
          taskActionType={
            treatmentEfficiencyState === 'treatmentEfficiencyNotStarted'
              ? 'rtmsTreatmentEfficiencyBefore'
              : 'rtmsTreatmentEfficiencyAfter'
          }
          sessionDate={nowPartialDate()}
          dialogOpen={taskListDialogOpen}
          setDialogOpen={setTaskListDialogOpen}
          activeTaskLists={activeTaskLists}
          fm={fm}
        />
      )}
    </React.Fragment>
  );
};

export default Sessions;
